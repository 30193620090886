<template>
  <div style="overflow-y: auto">
    <div class="container mt-2">
      <template v-if="renderableItems.length > 0">
        <div class="row" v-for="(row, i) in renderableItems" v-bind:key="i">
          <div class="" v-for="item in row" v-bind:key="item.id">
            <Item class="m-2" :item="item" :purchasable="true"></Item>
          </div>
        </div>
      </template>
      <div v-else class="text-center">
        You don't have any watched items! 
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"; 
import moment from "moment";

export default {
  computed: {
    ...mapGetters({
      watchedItems: 'watchlist/items',
      filters: "filters/filters",
      filtersLoaded: "filters/filtersLoaded",
    }),
    filteredItems() {
      let items = this.watchedItems;
      const filters = this.filters;

      console.log("Something changed");

      // Trade-lock filtering
      items = items.filter(item => {
        if (filters.tradelocked) {
          let minTime = moment().add(filters.tradelocked.min, 'days');
          let maxTime = moment().add(filters.tradelocked.max, 'days');

          // If max is 0, return instant only.
          if (filters.tradelocked.max == 0) {
            console.log("Checking for instant");
            // debugger
            if (moment(item.lockedUntil).isBefore()) {
              return true;
            } else {
              return false;
            }
          } else {
            console.log("Checking for locked");
            // They want between min and max
            if (moment(item.lockedUntil).isAfter()) {
              return true;
            } else {
              return false
            }
          }
        } else {
          return true;
        }
      })
      // Price filtering
      items = items.filter(item => {
        if (filters.price) {
          let minPrice = filters.price.min || 0;
          let maxPrice = filters.price.max || 9999999;

          return item.listPrice > minPrice && item.listPrice < maxPrice
        }

        return true;
      })

      // SortBy
      if (filters.sortBy) {
        let asc = filters.sortBy.indexOf('asc') > -1;

        items = items.sort((itemA, itemB) => {

          if (filters.sortBy.indexOf('price') > -1) {
            // sort by price
            if (asc) {
              if (itemA.listPrice > itemB.listPrice) return 1;
              if (itemA.listPrice == itemB.listPrice) return 0;
              if (itemA.listPrice < itemB.listPrice) return -1;
            } else {
              if (itemA.listPrice > itemB.listPrice) return -1;
              if (itemA.listPrice == itemB.listPrice) return 0;
              if (itemA.listPrice < itemB.listPrice) return 1;
            }
          } else if (filters.sortBy.indexOf('bestdeal') > -1) {
            // Sort by discount
            if (!itemA.discount) return -1;
            return itemA.discount > itemB.discount
          } else if (filters.sortBy.indexOf('float') > -1) {
            if (asc) {
              if (itemA.float > itemB.float) return 1;
              if (itemA.float == itemB.float) return 0;
              if (itemA.float < itemB.float) return -1;
            } else {
              if (itemA.float > itemB.float) return -1;
              if (itemA.float == itemB.float) return 0;
              if (itemA.float < itemB.float) return 1;
            }
          }
        });
      }

      return items;
    },
    renderableItems() {
      let toRender = this.filteredItems;

      const itemsPerRow = 4;
      // Do the 'binning' into groups of 4
      return toRender.reduce((col, item, i) => {
        if (i % itemsPerRow === 0)
          col.push([]); // Add a new row
        
        col[col.length - 1].push(item); // push item to the current row
        return col;
      }, []);
          
    }
  },
  methods: {
   
  },
}
</script>

<style lang="scss" scoped>

</style>